import home from './home';
import User from '../views/user/User.vue';
import NewUser from '../views/user/NewUser.vue';
import UserDetail from '../views/user/UserDetail.vue';

const user = {
  path: '/user',
  name: 'Users',
  component: User,
  meta: { parent: home },
};

const newUser = {
  path: '/user/new',
  name: 'New User',
  component: NewUser,
  meta: { parent: user },
};

const userDetail = {
  path: '/user/detail/:pk',
  name: 'User Detail',
  redirect: '/user/detail/:pk/user',
  component: UserDetail,
  meta: { parent: user, noLink: true },
};

// Tabs
import UserTab from '../views/user/tabs/UserTab.vue';
import BillingTab from '../views/user/tabs/BillingTab.vue';
import SipAccountTab from '../views/user/tabs/SipAccountTab.vue';
import RfidsTab from '../views/user/tabs/RfidsTab.vue';
import PinTab from '../views/user/tabs/PinTab.vue';
import UserGroupsTab from '../views/user/tabs/UserGroupsTab.vue';
import AccessTab from '../views/user/tabs/AccessTab.vue';
import LicensePlatesTab from '../views/user/tabs/LicensePlatesTab.vue';
import SipDoorsTab from '../views/user/tabs/SipDoorsTab.vue';
import ControlledEntriesTab from '../views/user/tabs/ControlledEntriesTab.vue';
import CamerasTab from '../views/user/tabs/CamerasTab.vue';
import SensorsTab from '../views/user/tabs/SensorsTab.vue';
import SessionsTab from '../views/user/tabs/SessionsTab.vue';
import AccessTokensTab from '../views/user/tabs/AccessTokensTab.vue';
import AppShortcutsTab from '../views/user/tabs/AppShortcutsTab.vue';
import ActionsTab from '../views/user/tabs/ActionsTab.vue';

userDetail.children = [
  {
    path: '/user/detail/:pk/user',
    name: 'User',
    component: UserTab,
    meta: { parent: userDetail },
  },
  {
    path: '/user/detail/:pk/billing',
    name: 'Billing',
    component: BillingTab,
    meta: { parent: userDetail },
  },
  {
    path: '/user/detail/:pk/sip-account',
    name: 'SIP Account',
    component: SipAccountTab,
    meta: { parent: userDetail },
  },
  {
    path: '/user/detail/:pk/rfids',
    name: 'RFID',
    component: RfidsTab,
    meta: { parent: userDetail },
  },
  {
    path: '/user/detail/:pk/pin',
    name: 'PIN',
    component: PinTab,
    meta: { parent: userDetail },
  },
  {
    path: '/user/detail/:pk/user-groups',
    name: 'User Groups',
    component: UserGroupsTab,
    meta: { parent: userDetail },
  },
  {
    path: '/user/detail/:pk/access',
    name: 'Access',
    component: AccessTab,
    meta: { parent: userDetail },
  },
  {
    path: '/user/detail/:pk/license-plates',
    name: 'License Plates',
    component: LicensePlatesTab,
    meta: { parent: userDetail },
  },
  {
    path: '/user/detail/:pk/sip-doors',
    name: 'SIP Doors',
    component: SipDoorsTab,
    meta: { parent: userDetail },
  },
  {
    path: '/user/detail/:pk/controlled-entries',
    name: 'Controlled Entries',
    component: ControlledEntriesTab,
    meta: { parent: userDetail },
  },
  {
    path: '/user/detail/:pk/cameras',
    name: 'Cameras',
    component: CamerasTab,
    meta: { parent: userDetail },
  },
  {
    path: '/user/detail/:pk/sensors',
    name: 'Sensors',
    component: SensorsTab,
    meta: { parent: userDetail },
  },
  {
    path: '/user/detail/:pk/sessions',
    name: 'Sessions',
    component: SessionsTab,
    meta: { parent: userDetail },
  },
  {
    path: '/user/detail/:pk/access-tokens',
    name: 'Access Tokens',
    component: AccessTokensTab,
    meta: { parent: userDetail },
  },
  {
    path: '/user/detail/:pk/app-shortcuts',
    name: 'App Shortcuts',
    component: AppShortcutsTab,
    meta: { parent: userDetail },
  },
  {
    path: '/user/detail/:pk/actions',
    name: 'Actions',
    component: ActionsTab,
    meta: { parent: userDetail },
  },
];

const userRoutes = [user, newUser, userDetail];

export default userRoutes;
